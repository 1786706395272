$icomoon-font-family: "effective" !default;
$icomoon-font-path: "effective" !default;

$icon-file-text: "\e926";
$icon-file-video: "\e92a";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?vd4o0i') format('truetype'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?vd4o0i') format('woff'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?vd4o0i##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-file-text {
  &:before {
    content: $icon-file-text;
  }
}
.icon-file-video {
  &:before {
    content: $icon-file-video;
  }
}
