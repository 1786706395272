$museo-font-family: "museo" !default;
$museo-font-path: "museo" !default;

@font-face {
  font-family: '#{$museo-font-family}';
  src: local('Museo300-Regular'),
  url('#{$museo-font-path}/Museo300-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: '#{$museo-font-family}';
  src: local('Museo700-Regular'),
  url('#{$museo-font-path}/Museo700-Regular.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
