.Container {
  overflow: hidden;

  height: 100%;
}

.Grid {
  list-style: none;

  display: grid;
  height: calc(100% - 80px);

  grid-template-rows: auto auto auto auto;
  grid-auto-flow: column;

  &Item {
    position: relative;

    width: calc((100vh - 80px) / 4);
    height: calc((100vh - 80px) / 4);

    &:global(.icon-file-text),
    &:global(.icon-file-video) {
      cursor: pointer;
    }

    &:before {
      position: absolute;
      top: 1vh;
      right: 1vh;

      font-size: 4vh;
      color: #fff;
      text-shadow: #000 0 0 2px;
    }
  }

  &Name {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 1vh 0;

    font-size: 2.2vh;
    color: #fff;
    text-align: center;
    text-shadow: #000 0 0 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &Image {
    display: block;

    width: 100%;
    height: 100%;
  }
}

.Footer {
  height: 80px;

  &Logo {
    float: left;

    display: block;

    margin: 20px 0 0 20px;

    &Image {
      display: block;

      height: 40px;
    }
  }
  &Copy {
    float: right;

    margin: 30px 20px 0 0;

    font-family: museo, serif;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
  }

  @media (max-width: 400px) {
    &Logo {
      margin-top: 25px;
    }

    &LogoImage {
      height: 30px;
    }

    &Copy {
      font-family: museo, serif;
      font-size: 16px;
    }
  }
}

.Modal {
  position: relative;

  width: 90vw;
  max-height: 85vh;

  padding: 20px;

  border: 1px solid #999;
  background-color: #fff;
  border-radius: 15px;

  display: flex;
  flex-direction: row;

  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    0% {
      opacity: 0;

      transform: scale(0.9);
    }
    100% {
      opacity: 1;

      transform: scale(1);
    }
  }

  &Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    inset: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.4);

    z-index: 1001;
  }

  &Portal {
  }

  &Column {
    flex: 1 1 auto;

    &:first-child {
      flex: 0 0 auto;

      width: 25%;

      margin-right: 3%;
    }
  }

  &Image {
    display: block;

    width: 100%;

    border-radius: 12px;
  }

  &Name {
    margin: 20px 0;

    font-size: 28px;
    text-align: center;
  }

  &Company {
    margin: -12px 0 20px;

    font-size: 20px;
    text-align: center;
  }

  &Info {
    overflow-y: auto;

    height: 100%;

    &P {
      margin-top: 15px;

      line-height: 1.25;
      white-space: pre-wrap;

      &:nth-child(odd) {
        font-weight: 700;
      }
    }
  }

  &Youtube {
    width: 100%;
    height: 70vh;

    &Video {
      width: 100%;
      height: 100%;
    }
  }

  &CloseBtn {
    position: absolute;
    top: -20px;
    right: -20px;

    padding: 5px;

    cursor: pointer;

    &:before {
      content: '✕';

      font-size: 20px;
      font-weight: 700;
      color: #fff;
      text-shadow: #000 0 0 2px;
    }
  }

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;

    width: 75vw;
    max-height: 75vh;

    &Column {
      &:first-child {
        width: auto;

        margin-right: 0;
        margin-bottom: 0;

        text-align: center;

        img {
          display: inline-block;

          width: auto;
          max-width: 150px;
          max-height: 10vh;
        }
      }
      &:last-child {
        overflow-y: auto;
      }
    }

    &Name {
      margin: 2vh 0;

      font-size: 3vh;
    }

    &Company {
      margin: -1vh 0 2vh;

      font-size: 2vh;
    }

    &Info {
      overflow-y: visible;

      height: auto;
    }

    &Youtube {
      height: 100%;
    }
  }
}

.StartScreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #000;

  opacity: 1;

  text-align: center;

  transition: all 0.5s ease;

  &Started {
    opacity: 0;

    transform: scale(1.5);

    pointer-events: none;
  }
  &Logo {
    display: block;

    height: 40vh;

    margin-bottom: 10vh;

    @media (orientation: portrait) {
      width: 80vw;
      height: auto;
    }
  }
  &Copy,
  &Hint {
    font-family: museo, serif;
    color: #fff;
  }
  &Copy {
    opacity: 1;

    font-size: 8vh;
    font-weight: bold;

    transition: opacity 0.5s ease;
  }
  &Next &Copy {
    opacity: 0;
  }
  &Hint {
    margin-top: -7vh;

    opacity: 0;

    font-size: 5vh;

    transition: opacity 0.5s ease 0.5s;
  }
  &Next &Hint {
    opacity: 1;
  }
  @media (max-width: 450px) {
    &Copy {
      font-size: 8vw;
    }
    &Hint {
      margin-top: -6vw;

      font-size: 5vw;
    }
  }
}
