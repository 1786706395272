@import '~reset-css';
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');

* {
  outline: none;
}

html,
body {
  height: 100%;

  font-family: 'PT Sans', Arial, sans-serif;
}

body {
  background-color: #000;
}

